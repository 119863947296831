.Appointement_container{
padding: 24px;     
}

.stepper {
  display: flex;
  align-items: center;
}

.stepper-nav {
  display: flex;
  align-items: center;
}

.step {
  text-align: center;
  color: #ccc;
  font-weight: bold;
}

.step.active .step-circle {
  background-color: #4285f4; /* Blue circle color for active step */
  color: #fff;
}

.step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #e0e0e0; /* Default circle color */
}

.step-line {
  width: 50px;
  height: 2px;
  background-color: #e0e0e0; /* Default line color */
  margin: 0 10px;
}

.step-line.active {
  background-color: #4285f4; /* Active line color */
}

.step-label {
  margin-top: 5px;
  font-size: 12px;
}

.step.active .step-label {
  color: #000; /* Active label color */
}
.appointment-button-style {
  margin-top: 30px;
  /* width: 25%; */
  padding: 15px ;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
}

.appointment-button-style:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .appointment-button-style{
    font-size: 12px;
  }
}