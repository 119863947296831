
.main_container_book_now{
  display: flex;
  justify-content: space-between;
  /* padding: 16px; */
  min-height: 50vh;
/* min-width: 1600px; */
}
.first_div_container_book_now{
  width: 50%;
  background: #f7f2f2;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  min-height: 30vh;
}
.second_div_container_book_now{
  width: 45%;
}

.slot-selector {
  font-family: Arial, sans-serif;
  padding: 10px;
}

.date-slider {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 10px 0;
  scrollbar-width: thin; /* For Firefox */
}

.date-slider::-webkit-scrollbar {
  height: 6px;
}

.date-slider::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.tab {
  cursor: pointer;
  flex: 0 0 auto; /* Prevents the tabs from shrinking */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 90px; /* Adjust based on desired size */
  transition: transform 0.2s, box-shadow 0.2s;
}

.tab:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
.slot_not_available{
  color: #007bff;
}
.tab.active {
  border-bottom: 3px solid #007bff;
  color: #007bff;
}

.tab-title {
  font-size: 1rem;
  margin: 0;
}

.tab-subtitle {
  font-size: 0.75rem;
  color: #888;
}

.slot-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.slot {
  padding: 8px 16px;
  border: 1px solid #007bff;
  border-radius: 4px;
  background-color: white;
  color: #007bff;
  cursor: pointer;
}

.slot_disable{
  padding: 8px 16px;
  border: 1px solid #1010104D;
  border-radius: 4px;
  background-color: #EFEFEF4D;
  color: #1010104D;
  
  cursor: pointer;
}

.slot:hover {
  background-color: #007bff;
  color: white;
}
.slot.selected {
  /* Style for selected slot */
  background-color:  #007bff;;
  border: 2px solid  #007bff;;
  color: white;
}

/* Responsive Design */
@media (max-width:1200px){

}
@media (max-width:991px){
  .main_container_book_now{
    display: flex;
    flex-direction: column;
  }
  .first_div_container_book_now{
    width: 100%;
  }
  .second_div_container_book_now{
    width: 100%;
  }

}
@media (max-width: 600px) {
  .tab {
    min-width: 90px; /* Adjusted for smaller screens */
    padding: 8px;
  }

  .tab-title {
    font-size: 0.875rem;
  }

  .tab-subtitle {
    font-size: 0.65rem;
  }

  .slot {
    padding: 6px 12px;
  }
}
