.container_near {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.main_conatiner_near_eyemaster{
    min-height: 49vh;
}




h2 {
    flex-grow: 1;
}

.search-bar {
    margin: 20px 0;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.professionals-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.professional-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e7e5e5;
    border-radius: 12px;
    padding: 15px 40px;
    /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); */
}

.professional_card_left{
    display: flex;
    align-items: center;
}

.profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-right: 15px;
    border: 0.1px solid lightgrey;
}

.profile-image2 {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    border-radius: 18px;
}

.professional-info {
    display: flex;
    flex-direction: column;
}

.professional-info h3 {
    margin: 0;
    font-size: 22px;
    /* color: #199FD9; */
}

.professional-info p {
    margin: 5px 0 0;
    color: #666;
    font-size: 16px;
    line-height: 20px;
}

.view_profile_button{
    /* color: #199FD9; */
    font-size: 18px;
}

.iconPosition{
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 18px;
}

@media (max-width: 767px) {
    .professional-card{
        display: flex;
        flex-direction: column;
        padding: 0;
        align-items: unset;
        background-color: #fff;
    } 
    .professional-card{
        width: 100%;
    }
    .view_profile_button{
        margin-top: 10px;
        margin-left: 10px;
    }

    .profile-image {
        width: 100px;
        height: 100px;
        margin-right: 15px;
        border-radius: 12px;
    }
    .professional-info h3 {
        font-size: 20px;
    }
    .professional-info p {
        font-size: 13px;
        line-height: 16px;
    }

}