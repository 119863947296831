.app-container {
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
  border-radius: 15px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.description {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.5;
}

.model-display {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
}

.model-placeholder {
  width: 150px;
  height: 150px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-placeholder2 {
  width: 100px ;
  height: 100px;
  min-width: 100px;
  background: #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symptom-title {
  text-align: center;
  margin-bottom: 16px;
  font-size: 16px;
}

.symptoms_heading {
  font-size: 24px;
  font-weight: 600;
}

.your_probably {
  font-size: 24px;
  font-weight: 600;
  color: #9e9a9a;
}

.converence_insu {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.symptom-buttons {
  display: grid;
  gap: 8px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.symptom-button-btn {
  flex: 1 1 calc(50% - 16px);
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s, border-color 0.3s;

}

.symptom-buttons-symptoms {
  display: grid;
  gap: 8px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
}

.symptom-button-btn-symptoms {
  flex: 1 1 calc(50% - 16px);
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s, border-color 0.3s;

}

.symptom-button {
  flex: 1 1 calc(50% - 16px);
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s, border-color 0.3s;
}

.symptom-button.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #0056b3;
}

.symptom-button:hover {
  background-color: #007bff;
  color: white;
}

@media (min-width: 600px) {
  .symptom-button {
    flex: 1 1 calc(33.333% - 16px);
  }
}

@media (max-width: 490px) {
  .symptom-buttons {
    display: grid;
    gap: 8px;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
  }

  .symptom-button {
    font-size: 12px;
  }
}

.submit-button {
  width: 30%;
  padding: 12px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}