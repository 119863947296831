.profile-container {
  /* max-width: 600px; */
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button,
.menu-button {
  background: none;
  border: none;
  font-size: 24px;
}

.profile-title {
  font-size: 24px;
  text-align: center;
}

.profile-content {
  margin-top: 20px;
}

.profile-picture {
  display: flex;
  align-items: center;
}

.profile-picture img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.doctor-name {
  font-size: 22px;
  font-weight: 500;
}

.doctor-profile-detail {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 15px;

}

.doctor-details {
  color: #666;
  font-size: 16px;
}

.working-time {
  margin-top: 10px;

}

.working-time,
.location {
  /* margin-top: 10px; */
  text-align: left;
}

.no-hours {
  color: #999;
}

.appointment-button {
  margin-top: 30px;
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.appointment-button:hover {
  background-color: #0056b3;
}


.time-slots-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin: 20px; */
}

.day-schedule {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  /* padding: 10px; */
  margin-top: 5px;
}

.day-name {
  font-weight: bold;
  /* margin-bottom: 5px; */
}

.time-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.time-slot-input {
  flex: 1;
  min-width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}



.experience-text{
  font-size: 16px;
  font-weight: 500;
}



/* Responsive Design */
@media (max-width: 767px) {
  .profile-container {
    padding: 10px;
  }

  .profile-title {
    font-size: 20px;
  }

  .appointment-button {
    font-size: 14px;
  }
  
  .doctor-name {
    font-size: 20px;
  }

  .doctor-details {
    font-size: 14px;
  }

  .experience-text{
    font-size: 14px;
  }
  
  
}

/* Responsive Design */
@media (max-width: 600px) {
  .profile-content {
    margin-top: 10px;
  }

  .time-inputs {
    flex-direction: column;
    gap: 10px;
  }

  .time-slot-input {
    width: 100%;
    min-width: unset;
    padding: 8px;
  }

  .day-schedule {
    padding: 8px;
  }

  .day-name {
    font-size: 1.2rem;
  }
}