.login_container{
    min-height: 40vh;
}
.profileContainer {
    border: 3px solid var(--primaryColor);
    width: 160px;
    height: 160px;
    border-radius: 100%;
    overflow: hidden;
    /* box-shadow: inset 0px 0px 0px 7px var(--primaryColor); */
    z-index: 2;
}

.camera {
    width: 25px;
    height: 25px;
    background: var(--primaryFillColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
}

/* .tabContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
} */

/* .left {
    width: 230px;
    border-right: 1px solid lightgray;
    padding: 20px 10px;
    min-height: 60vh;
    overflow-x: hidden;
}

.right {
    width: calc(100% - 230px);
} */

.docContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 15px;
}

.DCard {
    box-shadow: 0px 4px 4px 0px #0000000D;
    border-radius: 15px;
    padding: 25px;
    background: linear-gradient(180deg, rgba(255, 199, 181, 0.3) 0%, rgba(255, 183, 199, 0.3) 52.49%, rgba(255, 179, 204, 0.3) 100%);
    position: relative;
    overflow: hidden;
}

.dUpper {
    height: 140px;
}


.dbottom {
    width: 100%;
    height: 60px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    background: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filetype {
    width: 30px;
    margin-right: 10px;
}



@media screen and (max-width: 767px) {
    /* .tabContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
    } */
    
    /* .left {
        width: 100%;
        border-right: none;
        padding: 0;
        min-height: unset;
        overflow-x: auto;
    }
    
    .right {
        width: 100%;
    } */

    .docContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 15px;
    }

    .dUpper {
        height: 120px;
    }
    
}
@media screen and (max-width: 576px) {
    .left {
        font-size: 14px;
    }

    .profileContainer {
        border: 3px solid var(--primaryColor);
        width: 100px;
        height: 100px;
        border-radius: 100%;
        overflow: hidden;
        /* box-shadow: inset 0px 0px 0px 7px var(--primaryColor); */
        z-index: 2;
    }
    
    .camera {
        width: 25px;
        height: 25px;
        background: var(--primaryFillColor);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        position: absolute;
        right: 5px;
        bottom: 0px;
        cursor: pointer;
    }
}