.topHeaderContainer{
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto auto auto auto auto ;
    padding: 10px;
}
.topHeaderContainer2{
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    padding: 10px;
}


.slideritem1{
    border: 1px solid rgb(235, 225, 225);
    /* height: 200px; */
    width: 100%;
    border-radius: 6px;
    padding: 7px;
    transition: 0.3s ease;
}

.slideritem1:hover{
    box-shadow: 0 0 5px 2px rgb(235, 225, 225);
}

.sliderimg1{
    background: #fbf9f7;
    height: 70%;
}

.slidername1{
    font-size: 1.4rem;
    color: gray;
    margin-top: 10px;
}

.item_card_container {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: auto;
    gap: 20px;
    padding: 20px;
    flex-wrap: wrap;
    /* Wrap the items to adjust layout on smaller screens */
}

.item-card {
    width: 100%;
    max-width: 200px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.item-card:hover {
    transform: scale(1.05);
}

.item-card img {
    width: 80px;
    height: auto;
}

.item-card p {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    text-align: center;
}

/* section 2 */
.containe_gif_one {
    width: 100%;
}

.item_card_container_frame {
    display: flex;
    justify-content: center;
    width: 100%;
    /* overflow: auto; */
    gap: 60px;
    /* padding: 20px; */
    flex-wrap: wrap;

}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(50%) contrast(100%);
}

.containe_gif_two {
    width: 100%;
    height: 100%;
}

/* trending section */



.trending_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 15px 0;
    border-radius: 8px;

}

.Trending_title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
}

.view-all {
    background-color: #D9D9D9;
    color: #000000;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
}

.view-all:hover {
    background-color: #D9D9D9;
}



.trending_card_container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    /* Ensures the cards wrap on smaller screens */
    justify-content: center;
    /* Centers the cards horizontally */
}

.trending_card {
    /* position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



/* trending section */

/* unrevial */

.promo-section {
    display: flex;
    gap: 10px;
}

.promo_card_right {
    width: 60%;
    border-radius: 15px;
    /* padding: 20px; */

}

.promo_card_left {
    width: 40%;
    border-radius: 15px;
    /* padding: 20px; */

}


.sub_container_choose {
    /* width: 200px; */
    display: flex;
    width: 100%;
    overflow: auto;
    gap: 20px;
    padding: 20px;

}

.chooseCard {

    border-radius: 15px;

    min-width: 400px;
    width: 400px;
    padding: 30px;
}

.sub_container_choose::-webkit-scrollbar {
    display: none;
}

/* For Firefox */
.sub_container_choose {
    scrollbar-width: none;
    /* hide scrollbar in Firefox */
}

/* For Internet Explorer, Edge */
.sub_container_choose {
    -ms-overflow-style: none;
    /* hide scrollbar in IE and Edge */
}

/* unrevial */

/* follow us */

.follow_us_insta {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Ensures 4 columns */
    gap: 10px;
}

.follow_us_insta img {
    width: 100%;
    /* Images take the full width of the grid cell */
    height: auto;
    /* Maintains the aspect ratio */
}

.eye_master_heading {
    color: #00BEFA;
}

/* follow us */

/* eye wear */
.eye_wear {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Ensures 4 columns */
    gap: 10px;
}

.eye_wear img {
    width: 100%;
    height: auto;
}

/* eye wear */

/* footer css */



.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: url("../../../public/Img/footerbg1.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    /* padding-bottom: 10px; */
}

.sub_containter_footer {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 20px;
}

.footer-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.footer_left_sub_div {
    display: flex;
    flex-direction: column;
}

.footer-left .logo img {
    width: 100px;
}

.footer-middle {
    /* margin-top: 25px;
    display: flex;
    gap: 100px;
    padding-left: 20px; */
}

.footer-middle ul {
    list-style: none;
}

.footer-middle ul li {
    margin-bottom: 10px;
}

.footer-middle ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-right {
    width: 30%;
    padding: 30px;
    background: url('../../../public/Img/footerbg2.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-right .newsletter h4 {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}

.footer-right .newsletter input[type="email"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
}

.footer-right .newsletter button {
    padding: 10px 30px;
    background-color: #FFFFFF;
    color: #000000;
    border: none;
    border-radius: 16px;
    cursor: pointer;
}

.footer-right .social-media h4 {
    margin: 20px 0 10px;
}

.footer-right .social-icons a {
    margin-right: 10px;
}

.footer-right .social-icons img {
    width: 30px;
}



/* footer css */


/* latest news */
.latest_new_title {
    font-size: 40px;
    color: #000000;
    font-weight: 600;
}

.main_container_latest_news {
    display: flex;
    justify-content: space-between;
}

/* latest news */

/* review section */

.review_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* gap: 10px; */
}

.review_card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 340px;
    margin: 20px auto;
    overflow: hidden;
}

.review_card_content {
    padding: 15px;
}

.review_user_info {
    display: flex;
    align-items: center;
}

.review_avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.review_text_info h3 {
    font-size: 18px;
    margin-bottom: 5px;
}

.review_text_info p {
    font-size: 14px;
    color: #555;
}

.review_rating {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.review_stars {
    color: #ffcc00;
    font-size: 18px;
    margin-right: 5px;
}

.review_rating_score {
    font-size: 16px;
    font-weight: bold;
}

.review_product_image {
    width: 100%;
    height: auto;
    margin-top: 10px;
}

/* review section */

/* latest news */
.latest_news_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
}

.left_latest_container {
    width: 35%;
}

.right_latest_container {
    display: flex;
    flex-direction: column;
    width: 53%;
    gap: 10px;
}

.right_latest_upper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

/* latest news */

/* best seller */

.best_seller_product_card {
    background-color: #D9D9D9;
    border-radius: 10px;
    width: 250px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
}

.best_seller_card_header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #666;
    align-items: center;
}

.best_sellers_tilte {
    padding: 4px 16px;
    border: 1px solid #FFFFFF;
    background: #FFFFFF;
    border-radius: 8px;
}

.best_seller_product_image {
    margin: 10px 0;
}

.best_seller_product_image img {
    width: 100%;
    height: auto;
}

.best_seller_bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.best_seller_product_info {
    /* margin-top: 10px; */
}

.best_seller_product_title {
    font-size: 14px;

    color: #333;
}

.best_seller_brand_name {
    font-size: 14px;
    color: #888;
    margin-top: 1px;
}

.best_seller_price {
    font-size: 14px;

    color: #000;
    margin-top: 1px;
}

.best_seller_zoom_icon {}

.best_seller_zoom_icon img {
    width: 20px;
    height: 20px;
}

/* best seller */

/* header */

.navbar-brand img {
    max-width: 200px;
}

.navbar-light .navbar-nav .nav-link {
    color: #000;
    font-size: 14px;
    padding-left: 15px;
}




.form-control {
    border: 1px solid #ccc;
}

.form-control:focus {
    box-shadow: none;
    border-color: #000;
}

button i {
    color: #000;
}

.btn-outline-success {
    color: #FFFFFF;
    background-color: green;
    border-color: green;
}

.btn-outline-primary {
    background-color: rgb(108, 108, 243);
    border-color: rgb(108, 108, 243);
    color: #000000;
}

.btn-outline-dark {
    color: black;
    border-color: black;
}

.div_input_search {
    position: relative;
    max-width: 100%;
}

.search_icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}



/* header */
.choosText{
    font-size: 2.5rem;
    font-weight: 600;
}









@media (max-width: 1399px) {
    .slidername1{
        font-size: 1.2rem;
        color: gray;
        margin-top: 8px;
    }
}
@media (min-width: 1200px) {
    .trending_card {
        /* width: 23%; */
        /* 4 cards per row for extra large screens */
    }

    .review_container {
        grid-template-columns: repeat(3, 1fr);
    }

    .review_card {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 320px;
        margin: 20px auto;
        overflow: hidden;
    }
}
@media (max-width: 1120px) {
    .slidername1{
        font-size: 1rem;
        color: gray;
        margin-top: 7px;
    }
}
@media (max-width: 1024px) {
    .latest_news_container {
        flex-direction: column;
        /* Stack the items vertically */
        align-items: flex-start;
    }

    .left_latest_container {
        width: 100%;
        /* Full width for images */
    }

    .right_latest_container {
        width: 100%;
        /* Full width for content */
    }

   
}
@media (max-width: 991px) {
    .right_latest_upper {
        flex-direction: column;
        /* Stack the image and text vertically */
        align-items: flex-start;
    }
    .slidername1{
        font-size: 0.8rem;
        color: gray;
        margin-top: 7px;
    }
    .slideritem1{
        border: 0.5px solid rgb(235, 225, 225);
        border-radius: 5px;
        padding: 4px;
        transition: 0.3s ease;
    }
    .navbar-brand img {
        width: 120px;
    }
    .choosText{
        font-size: 1.8rem;
        font-weight: 600;
    }
}
@media (min-width: 900px) {
    .trending_card {
        /* width: 23%; */
        /* 3 cards per row for larger screens */
    }

    .review_container {
        grid-template-columns: repeat(3, 1fr);
    }

    .review_card {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 300px;
        margin: 20px auto;
        overflow: hidden;
    }
}

/* Media query for phone responsiveness */
@media (max-width: 767px) {
    .slidername1{
        font-size: 0.6rem;
        color: gray;
        margin-top: 7px;
    }
    .topHeaderContainer{
        display: grid;
        gap: 5px;
        grid-template-columns: auto auto auto auto auto auto ;
        padding: 10px;
    }
    .topHeaderContainer2{
        display: grid;
        gap: 5px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
        padding: 10px;
    }
    .trending_container {
        flex-direction: column;
        /* Stack title and button */
        align-items: flex-start;
        /* Align items to the start */
    }

    .Trending_title {
        font-size: 20px;
        /* Slightly smaller font size */
        margin-bottom: 10px;
        /* Add space between title and button */
    }

    .latest_new_title {
        font-size: 30px;
    }

    .view-all {
        /* width: 100%; */
        /* Full width button */
        padding: 10px;
        /* Adjust padding */
    }

    .promo-section {
        display: flex;
        flex-direction: column;
    }

    .promo_card_right {
        width: 100%;
        border-radius: 15px;
        padding: 20px;

    }

    .promo_card_left {
        width: 100%;
        border-radius: 15px;
        padding: 20px;

    }

    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .sub_containter_footer {
        width: 100%;
    }

    .choosText{
        font-size: 1.5rem;
        font-weight: 600;
    }

    .footer_left_sub_div {
        display: flex;
        justify-content: space-between;
    }

    .footer-left {
        display: flex;
        justify-content: space-between;
    }

    .footer-right {
        width: 100%;
        flex-direction: column;
        align-items: center;

    }

    .footer-left .logo img {
        width: 80px;
    }

    .footer-right {
        padding: 20px;
        width: 100%;
    }

    .footer-right .newsletter button {
        width: 100%;
    }

    .follow_us_insta {
        grid-template-columns: repeat(2, 1fr);
        /* Switch to 2 columns on tablets */
    }

    .eye_wear {
        grid-template-columns: repeat(2, 1fr);
        /* Switch to 2 columns on tablets */

    }

    .latest_news_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .left_latest_container,
    .right_latest_container {
        width: 100%;
        /* Full width for both image and text */
    }

    .right_latest_upper {
        flex-direction: column;
        align-items: flex-start;
    }

    h1.latest_new_title {
        font-size: 24px;
        /* Adjust font size for smaller screens */
    }

    p {
        font-size: 16px;
        /* Adjust paragraph font size for readability */
    }

    img {
        width: 100%;
        /* Ensure images scale down */
        height: auto;
        /* Maintain aspect ratio */
    }

    .navbar-brand img {
        width: 70px;
    }

    .navbar .nav-item {
        padding-left: 10px;
    }

    .d_flex_icon {
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-light .navbar-nav .nav-link {
        padding-left: 10px;
        font-size: 12px;
    }

    .btn-sm {
        padding: 5px 10px;
    }


}

@media (max-width: 600px) {
    .item_card_container {
        gap: 10px;
        padding: 10px;
        justify-content: space-around;
        /* Adjust spacing on smaller screens */
    }

    .item-card {
        width: 120px;
        /* Reduce the card size for smaller screens */
        padding: 10px;
    }

    .item-card img {
        width: 60px;
        /* Reduce image size */
    }

    .item-card p {
        font-size: 12px;
        /* Adjust font size */
    }

    .trending_card {
        /* width: 45%; */
        /* 2 cards per row for medium screens */
    }

    .footer-left {
        display: flex;
        flex-wrap: wrap;
        align-items: center;


    }

    .footer-middle {
        /* display: flex;
        flex-wrap: wrap;
        align-items: center; */
    }

    .footer_left_sub_div {
        display: flex;
        justify-content: space-between;
    }

    .review_container {
        grid-template-columns: 1fr;
    }

    .review_card_content {
        padding: 10px;
    }

    .review_text_info h3 {
        font-size: 16px;
    }

    .review_text_info p {
        font-size: 12px;
    }

    .review_rating_score {
        font-size: 14px;
    }

    .review_stars {
        font-size: 16px;
    }
}

@media (max-width: 576px) {

    .navbar-brand img {
        width: 60px;
    }

    .d_flex_icon {
        flex-direction: column;
        align-items: flex-start;
    }
    .slidername1{
        font-size: 0.5rem;
        color: gray;
        margin-top: 5px;
    }
    .topHeaderContainer{
        display: grid;
        gap: 5px;
        grid-template-columns: auto auto auto auto auto auto ;
        padding: 10px 6px;
    }
    .topHeaderContainer2{
        display: grid;
        gap: 5px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
        padding: 10px 6px;
    }
}

@media (max-width: 500px) {
    .topHeaderContainer::-webkit-scrollbar {
        display: none;
      }
      
    .topHeaderContainer{
        display: grid;
        gap: 5px;
        grid-template-columns: 80px 80px 80px 80px 80px 80px ;
        padding: 10px 6px;
        overflow-x: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .topHeaderContainer2::-webkit-scrollbar {
        display: none;
      }
      
    .topHeaderContainer2{
        display: grid;
        gap: 5px;
        grid-template-columns: 80px 80px 80px 80px 80px 80px ;
        padding: 10px 6px;
        overflow-x: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .choosText{
        font-size: 1.2rem;
        font-weight: 600;
    }
}


@media (max-width: 480px) {
    .Trending_title {
        font-size: 18px;
        /* Even smaller font size for smaller devices */
    }

    .view-all {
        font-size: 12px;
        /* Smaller font size for button */
    }

   

    .footer-left,
    .footer-right {
        padding: 10px;
        align-items: center;
    }

    .footer-middle {
        /* gap: 10px; */
    }

    .footer-left .logo img {
        width: 60px;
    }

    .footer-right .social-icons img {
        width: 25px;
    }

    .follow_us_insta {
        grid-template-columns: 1fr;
        /* Switch to 1 column on mobile */
    }

    .follow_us_insta img {
        width: 80%;
        /* Reduce image size on mobile */
        margin: 0 auto;
        /* Center the images */
    }

    .eye_wear {
        grid-template-columns: 1fr;
        /* Switch to 1 column on mobile */
    }

    .eye_wear img {
        width: 80%;
        /* Reduce image size on mobile */
        margin: 0 auto;
        /* Center the images */
    }
}

