.footer {
    background-color: #2c2c2c;
    color: #A7A7A7;
    /* padding: 70px 0 0 0; */
    font-family: "Poppins", Sans-serif;

  }
  .footer h5 {
    font-weight: bold;
    color: white;
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
  }
  .footer .footer-links a {
    color: #A7A7A7;
    text-decoration: none;
    font-size: 13px;
  }
  .footer .footer-links a:hover {
    text-decoration: underline;
  }
  .footer .social-icons a {
    color: #A7A7A7;
    margin-right: 10px;
    font-size: 20px;
  }
  .footer .disclaimer {
    text-align: center;
    padding: 30px 0;
    background-color: #3a3a3a;
    color: #b3b3b3;
    font-size: 14px;
    font-weight: bold;
  }

  .footer .disclaimer .link_style {
    text-decoration: none;
    text-align: center;
    padding: 30px 0;
    background-color: #3a3a3a;
    color: #b3b3b3 !important;
    font-size: 14px;
    font-weight: bold;
  }
  .link_style{
    text-decoration: none !important;
    
    
  }

  .link_style img{
    width: 30px !important;
  }
  .company_info img{
    width: 13px !important;
    color: white !important;
  }

  .company_info{
    color: #A7A7A7;
    text-decoration: none;
    font-size: 13px;
  }