:root {
    --primary-color: #007bff !important;
    --secondary-color: #fef0f2;
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the spinner controls in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}


.btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.activeTab {
  background: var(--primary-color) !important;
  color: white !important;

  svg {
    color: white !important;
  }
}

.activeTab2 {
  border-bottom: 2px solid var(--primary-color);
}


/* review status  */

.statusOuterr {
  background: rgba(241, 198, 3, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotr {
  width: 12px;
  height: 12px;
  background: rgb(241, 198, 3);
  border-radius: 100%;
}

.Statusr {
  font-size: 16px;
  color: #012A3E;
  font-weight: 500;
  text-wrap: nowrap;
}

/* disapproved status  */

.statusOuterin {
  background: rgba(237, 85, 101, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotin {
  width: 12px;
  height: 12px;
  background: rgba(237, 85, 101, 1);
  border-radius: 100%;
}

.Statusin {
  font-size: 16px;
  color: #012A3E;
  font-weight: 500;
  text-wrap: nowrap;
}



/* approved status  */

.statusOuterA {
  background: rgba(111, 151, 92, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotA {
  width: 12px;
  height: 12px;
  background: rgba(111, 151, 92, 1);
  border-radius: 100%;
}

.StatusA {
  font-size: 16px;
  color: rgba(111, 151, 92, 1);
  font-weight: 500;
}


.heading {
    font-size: 1.8rem;
    font-weight: 600;
}

.sub_heading{
    font-weight: 600;
    font-size: 1.1rem;
}

.labelHeading {
    font-size: 18px;
    font-weight: 500;
}

.iconStyle {
    position: absolute;
    right: 10px;
    top: 6px;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    color: gray;
    z-index: 10 !important;
}

.headerDropDownContainer{

    .btn-secondary {
        color: #000 !important;
        background-color: white !important;
        border-color: white !important;
        padding: 0 !important;
    }
    .btn-secondary:hover {
        color: #000 !important;
        background-color: white !important;
        border-color: white !important;
    }

}
.popover .popover-arrow::after, .popover .popover-arrow::before {
    display: none !important;
    content: unset !important;
    border-style: none !important;
}

.pointer {
    cursor: pointer;
}

.word-wrap{
    word-wrap: break-word !important;
}
  .activeTab2 {
    border-bottom: 2px solid rgba(36, 124, 255, 1);
    color: rgba(36, 124, 255, 1) !important;
  }


  .noDataScreen{
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }


.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    /* Ensure it appears just below the text */
    left: 0;
    z-index: 1000;
    /* Keep the dropdown visible above other elements */
    background-color: white;
    /* Adjust background color if needed */
    min-width: 150px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Optional styling for dropdown */
}

.dropdown:hover .dropdown-menu {
    display: block;
    /* Show dropdown on hover */
}

.dropdown-toggle {
    cursor: pointer;
    color: #000;
    text-decoration: none;
}




.otp-input-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.otp-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.otp-input:focus {
    outline: none;
    border-color: #007bff;
}

.list-style{
    list-style: none;
    border: 1px solid rgb(222, 216, 216);
    display: flex;
    flex-direction: column;
    padding: 3px 0px;
}

.list-style li{
    padding: 7px 5px;
}
.list-style li:hover{
    background: #efebeb;
    cursor: pointer;
}
.thankuText{
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
}
.thankuText2{
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    color: gray;
}

.thankuIcon{
    font-size: 4rem;
    text-align: center;
    color: var(--primary-color);
}

.closeIcon{
    font-size: 2rem !important;
}

.bannerSection{
    background: url('/public/images/banner3.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 130px;
    width: 100%;
}
.registrationBanner{
    background: url('/public/images/banner1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* min-height: 300px; */
    width: 100%;
    
}

.banner1text{
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 4.5rem;
}

.banner2text{
    font-size: 1.4rem;
    color: gray;
}

.cs-btn{
    border: 1px solid black;
    background: black;
    color: white;
    border-radius: 25px;
    padding: 8px 20px;
    margin-top: 15px;
}

.profilePreview{
    border-radius: 100%;
    width: 150px;
    height: 150px;
    border: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.uploadtext{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: lightgray;
    font-size: 1.2rem;
}

.uploadIcon{
    font-size: 3rem;
}

.fileContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}

.fileStyle{
    width: 100px;
    height: 100px;
    word-wrap: break-word; /* Ensures long words break */
  overflow-wrap: break-word; /* Ensures compatibility */
  word-break: break-word; /* Breaks long text to fit */
    /* display: flex; */
}



.fs-14{
    font-size: 14px;
}

.f-14{
    font-size: 14px;
}

.f-17{
    font-size: 17px;
}
.f-18{
    font-size: 18px;
}
.f-20{
    font-size: 20px;
}

.fw-500{
    font-weight: 500 !important;
}

.fw-600{
    font-weight: 600 !important;
}
.fw-700{
    font-weight: 700 !important;
}

.text-gray{
    color: rgb(182, 178, 178) !important;
}












@media (max-width: 767px) {
    .banner1text{
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.5rem;
    }
    
    .banner2text{
        font-size: 1rem;
        color: gray;
    }
    .cs-btn{
        border: 1px solid black;
        background: black;
        color: white;
        border-radius: 25px;
        padding: 5px 15px;
        margin-top: 15px;
        font-size: 0.8rem;
    }
    .otp-input-container {
        gap: 8px;
    }

    .otp-input {
        width: 40px;
        height: 40px;
        font-size: 18px;
    }

    .thankuText{
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
    }
    .thankuText2{
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        color: gray;
    }
    
    .thankuIcon{
        font-size: 3rem;
        text-align: center;
        color: var(--primary-color);
    }
}

@media (max-width: 576px) {
    .otp-input-container {
        gap: 6px;
    }

    .otp-input {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }

    .heading {
        font-size: 20px;
        font-weight: 600;
    }

    .fs-14{
        font-size: 12px;
    }

   
}



@media (max-width: 576px) {
    
    .thankuText{
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
    }
    .thankuText2{
        font-size: 0.95rem;
        font-weight: 500;
        text-align: center;
        color: gray;
    }
    
    .thankuIcon{
        font-size: 2.8rem;
        text-align: center;
        color: var(--primary-color);
    }

    .banner1text{
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
    }
    
    .banner2text{
        font-size: 0.6rem;
        color: gray;
    }
}