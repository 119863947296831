.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spinner {
    font-size: 18px;
    font-weight: 600;
    color: white;
  }